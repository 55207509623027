
$(".hero-content .title h1").html(
  $(".hero-content .title h1").html()
    .replace(/((?!<sup>\s*))&reg;((?!\s*<\/sup>))/gi, '<sup>&reg;</sup>') // wrap &reg; if not wrapped yet
    .replace(/((?!<sup>\s*))®((?!\s*<\/sup>))/gi, '<sup>&reg;</sup>') // wrap ® if not wrapped yet
);


jQuery(document).ready(function($){

$("[data-scroll]").each(function(index, el) {
   var $elm = $(this);
   $elmWatcher  = scrollMonitor.create($elm, -50);

$elmWatcher.enterViewport(function() {
    $elm.addClass('animActive');
});
});


$(".scrollto").on("click", function(){
    $("html,body").animate({scrollTop: $("#aboutus").position().top - 80}, 1000);
});


var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menumob'),
        'padding': 256,
        'tolerance': 70
    });


var fixed = document.querySelector('.headermob');

slideout.on('translate', function(translated) {
  fixed.style.transform = 'translateX(' + translated + 'px)';
});

slideout.on('beforeopen', function () {
  fixed.style.transition = 'transform 300ms ease';
  fixed.style.transform = 'translateX(256px)';
  $('.hamburger').addClass('h-active');
});

slideout.on('beforeclose', function () {
  fixed.style.transition = 'transform 300ms ease';
  fixed.style.transform = 'translateX(0px)';
  $('.hamburger').removeClass('h-active');
});

slideout.on('open', function () {
  fixed.style.transition = '';
});

slideout.on('close', function () {
  fixed.style.transition = '';
});




// makes the parallax elements
function parallaxIt() {
  // create variables
  var $fwindow = $(window);
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  var $contents = [];
  var $backgrounds = [];

  // for each of content parallax element
  $('[data-type="content"]').each(function(index, e) {
    var $contentObj = $(this);

    $contentObj.__speed = ($contentObj.data('speed') || 1);
    $contentObj.__fgOffset = ($contentObj.data('offset') || $contentObj.offset().top);
    $contents.push($contentObj);
  });

  // for each of background parallax element
  $('[data-type="background"]').each(function() {
    var $backgroundObj = $(this);

    $backgroundObj.__speed = ($backgroundObj.data('speed') || 1);
    $backgroundObj.__fgOffset = ($contentObj.data('offset') || $contentObj.offset().top);
    $backgrounds.push($backgroundObj);
  });

  // update positions
  $fwindow.on('scroll resize', function() {
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    $contents.forEach(function($contentObj) {
      var yPos = $contentObj.__fgOffset - scrollTop / $contentObj.__speed;

      $contentObj.css('transform', "translateY("+ -yPos+"px)");
      //console.log("translateY("+ yPos+"px)");
    })

    $backgrounds.forEach(function($backgroundObj) {
      var yPos = -((scrollTop - $backgroundObj.__fgOffset) / $backgroundObj.__speed);

      $backgroundObj.css({
        backgroundPosition: '50% ' + yPos + 'px'
      });
    });
  });

  // triggers winodw scroll for refresh
  $fwindow.trigger('scroll');
};

parallaxIt();




var cl = $(".main-nav .nav").children().not(".logo").clone(true)

$('#menumob').append(cl).wrapInner('<ul></ul>');

  // mobile menu
  $('.hamburger').click(function (event) {
    $(this).toggleClass('h-active');
    slideout.toggle();
  });


function fixedHeader(){
	var $winPos = $(window).scrollTop();
	var $header = $(".header-main");
  var $headerPos = $(".header-wrap").position().top;
  console.log($headerPos);
    if($winPos > $headerPos){
    	$header.addClass('header-active');
    }else{
    	$header.removeClass('header-active');
    }
}

fixedHeader();

// fixed header
$(window).scroll(function(event) {
     fixedHeader();
});


$(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 2500) {
        $('.gotop').fadeIn();
      } else {
        $('.gotop').fadeOut();
      }
    });

    // scroll body to 0px on click
    $('.gotop').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  });









});
